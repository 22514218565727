import React from "react";
import './styles.css'
import Header from "../../components/headerFesta/header";
import Banner from "../../components/banner/banner";
import Footer from "../../components/footerFesta/footer";

function MenuFesta(){
    return(
        <>
        <Header />
        <Banner />
        <div className="title-div">
            <span className="title">
                Menu<br/>
            </span>
        </div>
        <div className="MenuPart">
            <div className="MenuPart-title">
                <span>Entradas</span>
            </div>
            <div className="MenuPart-body">
                <span>
                    - Copinho de pinga com carpaccio ao molho de alcaparras e parmesão<br/>
                    - Crostini de polenta com calabresa e salsa de tomate<br/>
                    - Queijo coalho tostado com melaço de cana<br/>
                    - Bolinho de carne de panela em dip de pimenta<br/>
                    - Phylo com queijo brie e geleia de damasco<br/>
                    - Mini kibe na coalhada seca e hortelã<br/>
                    - Aligot com ragu de costela<br/>
                    - Escondidinho de carne seca<br/><br/>
                </span>
            </div>
        </div>
        <div className="MenuPart">
            <div className="MenuPart-title">
                <span>Jantar</span>
            </div>
            <div className="MenuPart-title-2">
                <span>Salda</span>
            </div>
            <div className="MenuPart-body">
                <span>
                Seleção de folhas nobres da estação com manga, nozes Pekan, lascas de parmesão e crispes de Parma ao molho de redução de balsâmico <br/><br/>
                </span>
            </div>
            <div className="MenuPart-title-2">
                <span>Pratos Quentes</span>
            </div>
            <div className="MenuPart-body">
                <span>
                    - Filé mignon em mix de cogumelos selvagens<br/>
                    - Batatas ao gratin<br/>
                    - Arroz branco<br/>
                    - Frango ao molho de laranja com pimenta rosa<br/>
                    - Risoto de brie com rúcula<br/>
                    - Raviolone de Alcachofra na manteiga e salvia<br/>
                    - Ravióli de queijos ao napolitano<br/><br/>
                </span>
            </div>
        </div>
        <div className="MenuPart">
            <div className="MenuPart-title">
                <span>Sobremesa</span>
            </div>
            <div className="MenuPart-body">
                <span>
                    Brownnie de chocolate com amêndoas e sorvete de vanilla, framboesas e calda de chocolate.<br/><br/>
                </span>
            </div>
        </div>
        <div className="MenuPart">
            <div className="MenuPart-title">
                <span>Comidinhas da madrugada</span>
            </div>
            <div className="MenuPart-body">
                <span>
                    - Mini burguer gourmet com cebolas carameladas<br/>
                    - Mini churros de doce de leite<br/><br/>
                </span>
            </div>
        </div>
        <div className="MenuPart">
            <div className="MenuPart-title">
                <span>Bebidas</span>
            </div>
            <div className="MenuPart-title-2">
                <span>Não Alcoólicas</span>
            </div>
            <div className="MenuPart-body">
                <span>
                    - Água mineral com e sem gás<br/>
                    - Suco Laranja com morango<br/>
                    - Suco Abacaxi com hortelã<br/>
                    - Coca normal e coca zero<br/>
                    - Guaraná normal e diet<br/><br/>
                </span>
            </div>
            <div className="MenuPart-title-2">
                <span>Alcoólicas</span>
            </div>
            <div className="MenuPart-body">
                <span>
                    - Cerveja Original<br/>
                    - Vinho Salton Paradoxo Corte 2021<br/>
                    - Espumante Salton Séries Moscato Rosé<br/><br/>
                </span>
            </div>
        </div>
        <div className="MenuPart">
            <div className="MenuPart-title">
                <span>Na Saída da Festa</span>
            </div>
            <div className="MenuPart-body">
                <span>
                    - Café expresso<br/>
                    - Infusão de chás servida em Samovar<br/>
                    - Bolo caseiro especial da chácara de laranja com erva doce<br/>
                    - Bolo de cenoura com chocolate<br/>
                    - Biscoitinhos deliciosos preparados em nossa casa<br/><br/>
                </span>
            </div>
        </div>
        <Footer />
        </>
    )
}

export default MenuFesta;