import React from "react";
import './styles.css'
import Header from "../../components/headerFesta/header";
import Banner from "../../components/banner/banner";
import Footer from "../../components/footerFesta/footer";
import Flores from '../../assets/FloresCrochet8.png'

function Informacoes(){
    return(
        <>
        <Header />
        <Banner />
        <div className="title-div">
            <span className="title-I">
                Informações extras<br/>
            </span>
        </div>

        <div className="info">
            <div className="pix-qrcode-img">
                <img id='qrcode-img' src={Flores} alt="Flores"></img>
            </div>
        </div>
        <div className="info">
            <span className="op-text">
                - Os portas guardanapos são uma lembrança para levarem <br/>
                *sem o guardanapo <br/><br/><br/>
                - Caso postem fotos do nosso casamento no instagram, lembrem de nos marcar por lá.<br/>
                @sanches.anna<br/>
                @edu.bertin<br/><br/><br/>
            </span>
        </div>
        
        
        <Footer />
        </>
    )
}

export default Informacoes;